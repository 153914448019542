import React from "react"
import { Helmet } from "react-helmet"

const ReturnPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Return Policy | The Betty Rocker</title>
      </Helmet>
      <div className="container py--30">
        <h1 className="h3 mb--40">
          <strong>Return Policy - Updated 10/25/21</strong>
        </h1>

        <p>
          Please reach out to us at{" "}
          <a
            href="mailto:support@thebettyrocker.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@thebettyrocker.com
          </a>{" "}
          in the event you would like to return a program or product, and review
          the following policies about returns.
        </p>
        <h5 className="mb--20">
          <strong>Digital Products</strong>
        </h5>
        <p>
          Digital Products are products delivered via the internet. These
          include meal plans and workout programs. Digital products are backed
          by our money back satisfaction guarantee. Length of guarantee varies,
          and is generally related to program length.
        </p>
        <p>
          If you are not happy with your purchase of one of your digital
          products please contact us at{" "}
          <a
            href="mailto:support@thebettyrocker.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@thebettyrocker.com
          </a>{" "}
          to request a refund within the return timeframe.
        </p>
        <table>
          <thead>
            <tr>
              <td>30-Day Money Back Guarantee</td>
              <td>60-Day Money Back Guarantee</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <ul>
                  <li>7-Day Meal Plan</li>
                  <li>30-Day Meal Plan</li>
                  <li>30-Day Booty and Abs Challenge</li>
                  <li>My Dinner Plan</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Body Fuel System</li>
                  <li>90-Day Challenge</li>
                  <li>Home Workout Domination</li>
                  <li>Home Workout Domination 2</li>
                  <li>Lioness</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <h5 className="mb--20">
          <strong>Physical Products</strong>
        </h5>
        <p>
          Physical products are products delivered to a physical address. These
          include Whole Betty products and Betty Rocker apparel and merchandise.
        </p>
        <p>
          If you’re unhappy with a physical product, please contact us within 30
          days of purchase at{" "}
          <a
            href="mailto:support@thebettyrocker.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@thebettyrocker.com
          </a>
          , and see below for instructions regarding specific products.
        </p>
        <h5 className="mb--20">
          <strong>Whole Betty Supplements</strong>
        </h5>
        <p>
          In the event of a damaged product, we will send a replacement at no
          extra charge. For all other returns, please note there is a $5
          restocking fee and shipping costs are at your expense.
        </p>
        <p>
          Contact us at{" "}
          <a
            href="mailto:support@thebettyrocker.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@thebettyrocker.com
          </a>{" "}
          within 30 days of your original purchase and we’ll be happy to help
          you.
        </p>
        <h5 className="mb--20">
          <strong>Betty Rocker Apparel</strong>
        </h5>
        <p>
          If your apparel doesn’t fit, please contact us at{" "}
          <a
            href="mailto:support@thebettyrocker.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@thebettyrocker.com
          </a>{" "}
          within 30 days of purchase to request a refund.
        </p>
        <p>
          Please be sure to carefully read our sizing guide prior to purchase to
          ensure you get the right size. To return your product and receive a
          refund, it must be unworn and in new condition.
        </p>
        <p>
          We currently cannot do exchanges, however our friendly customer
          support team will be happy to help you choose a better size so you can
          place a new order.
        </p>
        <p>
          Return shipping is your responsibility. We do not refund your original
          purchase shipping cost. All refunds are issued minus a $5 restocking
          fee.
        </p>
        <h5 className="mb--20">
          <strong>Betty Rocker Merchandise</strong>
        </h5>
        <p>
          Please contact us at{" "}
          <a
            href="mailto:support@thebettyrocker.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@thebettyrocker.com
          </a>{" "}
          within 30 days of your original purchase for a refund if you are
          unhappy with your merchandise. Return your product in unused and new
          condition to qualify for a refund.
        </p>
        <p>
          Return shipping is your responsibility. We do not refund your original
          purchase shipping cost. All refunds are issued minus a $5 restocking
          fee.
        </p>
      </div>
    </>
  )
}
export default ReturnPolicy
